exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-et-ettevottest-js": () => import("./../../../src/pages/et/ettevottest.js" /* webpackChunkName: "component---src-pages-et-ettevottest-js" */),
  "component---src-pages-et-index-js": () => import("./../../../src/pages/et/index.js" /* webpackChunkName: "component---src-pages-et-index-js" */),
  "component---src-pages-et-kontakt-js": () => import("./../../../src/pages/et/kontakt.js" /* webpackChunkName: "component---src-pages-et-kontakt-js" */),
  "component---src-pages-et-portfoolio-js": () => import("./../../../src/pages/et/portfoolio.js" /* webpackChunkName: "component---src-pages-et-portfoolio-js" */),
  "component---src-pages-et-teenused-js": () => import("./../../../src/pages/et/teenused.js" /* webpackChunkName: "component---src-pages-et-teenused-js" */),
  "component---src-pages-et-tehasemaja-js": () => import("./../../../src/pages/et/tehasemaja.js" /* webpackChunkName: "component---src-pages-et-tehasemaja-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-no-contact-js": () => import("./../../../src/pages/no/contact.js" /* webpackChunkName: "component---src-pages-no-contact-js" */),
  "component---src-pages-no-index-js": () => import("./../../../src/pages/no/index.js" /* webpackChunkName: "component---src-pages-no-index-js" */),
  "component---src-pages-no-our-story-js": () => import("./../../../src/pages/no/our-story.js" /* webpackChunkName: "component---src-pages-no-our-story-js" */),
  "component---src-pages-no-portfolio-js": () => import("./../../../src/pages/no/portfolio.js" /* webpackChunkName: "component---src-pages-no-portfolio-js" */),
  "component---src-pages-no-prefab-js": () => import("./../../../src/pages/no/prefab.js" /* webpackChunkName: "component---src-pages-no-prefab-js" */),
  "component---src-pages-no-solutions-js": () => import("./../../../src/pages/no/solutions.js" /* webpackChunkName: "component---src-pages-no-solutions-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-prefab-js": () => import("./../../../src/pages/prefab.js" /* webpackChunkName: "component---src-pages-prefab-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

